$theme-colors: (
  "primary": #5798bc,
  "primary-dark": #1f6a8c,
  "primary-light": #8ac9ef,
  "secondary": #838385,
  "success": #008e7a,
  "danger": #b00020,
  "warning": #ff8542,
  "info": #a8c9dc,
  "dark": #374955,
);

$primary-color: #5798bc;
$primary-color-dark: #1f6a8c;
$primary-color-light: #8ac9ef;
$white: #fff;
$black: #000;
$warning: #ff8542;
$warning-light: #ffa562;
$success: #008e7a;
$danger: #b00020;
$info: #b481a4;
$dark: #374955;
$bg-light: #f8f9fa;

// Override default blue palette with FABRIC theme blue.
$blue-lighten-6: #e4f4f8 !default;
$blue-lighten-5: #bbe4ee !default;
$blue-lighten-4: #95d2e4 !default;
$blue-lighten-3: #78c0d8 !default;
$blue-lighten-2: #68b3d1 !default;
$blue-lighten-1: #5fa6ca !default;
$blue-base: #5798bc !default;
$blue-darken-1: #4d86a9 !default;
$blue-darken-2: #477595 !default;
$blue-darken-3: #3a5771 !default;
$blue-accent-1: #94cbff !default;
$blue-accent-2: #61b2ff !default;
$blue-accent-3: #2e99ff !default;
$blue-accent-4: #148cff !default;

// grey palette.
$grey-lighten-5: #fafafa !default;
$grey-lighten-4: #f5f5f5 !default;
$grey-lighten-3: #eeeeee !default;
$grey-lighten-2: #e0e0e0 !default;
$grey-lighten-1: #bdbdbd !default;
$grey-base: #9e9e9e !default;
$grey-darken-1: #757575 !default;
$grey-darken-2: #616161 !default;
$grey-darken-3: #424242 !default;
$grey-darken-4: #212121 !default;

@import "~bootstrap/scss/bootstrap";
