body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  line-height: 180%;
  text-align: left;
  color: rgba(0, 0, 0, 0.85);
}
